import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to create a project
  CREATE_NEW_PROJECT({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/create-new-project", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a project
  EDIT_PROJECT({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/projects/edit-project/${payload.projectId}`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get their projects
  GET_MY_PROJECTS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/projects/get-my-projects", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific project
  GET_PROJECT({ }, projectId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/projects/get-project/${projectId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to create new task
  CREATE_TASK({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/projects/create-new-task`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a task
  EDIT_TASK({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/projects/edit-task`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific task
  GET_TASK({ }, taskId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/projects/get-task/${taskId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to create a milestone
  CREATE_MILESTONE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/create-new-milestone", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a milestone
  EDIT_MILESTONE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/projects/edit-milestone", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a milestone
  DELETE_MILESTONE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/projects/${payload.projectId}/delete-milestone/${payload.milestoneId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a milestone
  GET_MILESTONE({ }, milestoneId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/projects/get-milestone/${milestoneId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to submit a project collaborator
  ADD_PROJECT_COLLABORATOR({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/add-collaborator", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to remove a project collaborator
  REMOVE_PROJECT_COLLABORATOR({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/remove-collaborator", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //To start a new report
  START_NEW_REPORT({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/start-new-report", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a collaborator to fetch a report
  GET_REPORT({ }, reportId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/projects/get-report/${reportId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a collaborator to enter the entry details
  ADD_REPORT_ENTRY({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/add-report-entry", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a collaborator to edit an entry details
  EDIT_REPORT_ENTRY({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/projects/edit-report-entry", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a collaborator to delete an entry
  DELETE_REPORT_ENTRY({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/projects/report/${payload.reportId}/delete-report-entry/${payload.entryId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  ADD_REPORT_TEMPLATE_TO_PROJECT({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/add-report-template-to-project", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  REMOVE_REPORT_TEMPLATE_FROM_PROJECT({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/projects/remove-report-template-from-project", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
