import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to post a new job
  POST_NEW_JOB({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/jobs/post-new-job", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit their posted job
  EDIT_JOB({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/jobs/edit-job/${payload.jobId}`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get their jobs
  GET_MY_JOBS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/jobs/get-my-jobs", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific job
  GET_JOB({ }, jobId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/jobs/get-job/" + jobId)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
