import { io } from "socket.io-client";
import { fetchToken } from "./utility";

const socket = io(process.env.VUE_APP_BACKEND_BASE_URL, {
    transports: ['websocket'],
    upgrade: false,
    reconnection: true,
    query: {
        token: fetchToken()
    }
})

export default socket

