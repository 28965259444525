import axios from "axios"
import store from "../store/index";
import { clearAuthCredentials, fetchToken } from "../utility";

let headers = {}

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
    headers,
})

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.withCredentials = true //For it to be able to send the csrftoken to server

axiosInstance.interceptors.request.use(
    async config => {
        let token = fetchToken()

        let unParsedUser = localStorage.getItem("user");

        let user
        if (unParsedUser && unParsedUser != "undefined" && unParsedUser != "") {
            user = JSON.parse(unParsedUser);
        }

        if (unParsedUser == "undefined" || unParsedUser == "") {
            localStorage.removeItem(user)
        }

        if (token && token != "undefined" && user) {
            // There is a valid token
            store.commit("user/SET_TOKEN", token)
            store.commit("user/SET_AUTH_USER", user)
        }

        if (token) {
            if (process.env.NODE_ENV == "development" && process.env.VUE_APP_BACKEND_BASE_URL == "https://inspectorama-test.herokuapp.com") {
                config.headers.Authorization = `Bearer ${token}`
            }
            store.commit("user/SET_TOKEN", token)
        }

        return config
    },
    error => {
        return Promise.reject(error);
    })


axiosInstance.interceptors.response.use(response => {
    // Edit response config

    return response;
}, async error => {
    if (error.response && error.response.data && (
        error.response.data.detail == "Invalid token." ||
        error.response.data.detail == "Authentication credentials were not provided.")
    ) {
        clearAuthCredentials()

        return Promise.reject(null)
    } else {
        return Promise.reject(error);
    }
});

export default axiosInstance
