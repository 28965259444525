import axiosInstance from "../helpers/axiosInstance";

const state = {
  cart: []
};

const mutations = {
  SET_CART: (state, cart) => {
    state.cart = cart
  }
};

const actions = {
  //For a user to add item to cart
  ADD_ITEM_TO_CART({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/carts/add-item-to-cart", payload)
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

   //For a user to remove item from cart
   REMOVE_ITEM_FROM_CART({ commit }, cartItemId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/carts/remove-item-from-cart/${cartItemId}`)
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get their cart
  GET_MY_CART({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/carts/get-my-cart")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
  
  //For a user to clear their cart
  CLEAR_MY_CART({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/carts/clear-my-cart")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
