import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to get all subscription plans
  GET_SUBSCRIPTION_PLANS({ }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/subscriptions/get-subscription-plans")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
