// import Vue from 'vue';
// import Vuex from 'vuex';
import { createStore } from 'vuex'
import user from './user'
import inspector from './inspector'
import utilities from './utilities'
import order from './order'
import chat from './chat'
import report from './report'
import wallet from './wallet'
import payment from './payment'
import referral from './referral'
import customerReview from './customerReview'
import newsletter from './newsletter'
import blog from './blog'
import quotation from './quotation'
import surcharge from "./surcharge"
import admin from './admin'
import project from './project'
import reportTemplate from './reportTemplate'
import subscription from './subscription'
import cart from './cart'
import job from './job'

export default createStore({
  modules: {
    user, inspector, utilities, order, chat, report, wallet, payment, referral, customerReview,
    newsletter, blog, quotation, surcharge, admin, project, reportTemplate, subscription, cart, job
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  // plugins: [createPersistedState()]
});
