import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to create a report template
  CREATE_REPORT_TEMPLATE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/report-templates/create-report-template", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a report template
  EDIT_REPORT_TEMPLATE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch('/report-templates/edit-report-template', payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get their report templates
  GET_MY_REPORT_TEMPLATES({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/report-templates/get-my-report-templates", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific template
  GET_REPORT_TEMPLATE({ }, templateId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/report-templates/get-a-report-template/${templateId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to add a field to a report template
  ADD_FIELD_TO_REPORT_TEMPLATE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/report-templates/add-field-to-report-template", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a field in a report template
  EDIT_FIELD_IN_REPORT_TEMPLATE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/report-templates/edit-field-in-report-template", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a field from a report template
  DELETE_FIELD_FROM_REPORT_TEMPLATE({ }, { templateId, fieldId }) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/report-templates/${templateId}/delete-field-from-report-template/${fieldId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
